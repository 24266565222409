import React, { useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import {Formik, Form, Field, ErrorMessage} from "formik";
// import * as Yup from "yup";
import Icon from "@mdi/react";
import { mdiEmail, mdiPhoneInTalk, mdiMapMarker } from "@mdi/js";
// import axios from "axios";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
// import {RotatingLines} from "react-loader-spinner";

import PageHeader from "./common/PageHeader";
import { Link } from "react-router-dom";
// import {ErrorMessage} from "formik";
// import div from "react-reveal";

const ContactUs = () => {
  const form = useRef();

  // console.log(form);

  // const [loader, setLoader] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone: "",
    message: "",
  });

  const [fieldErrors, setFieldErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone: "",
    message: "",
  });

  const handleFieldChange = (e) => {
    let vals = { ...formData };
    vals[e.target.name] = e.target.value;
    setFormData({ ...vals });
  };

  let infoBarArr = [
    {
      title: "Address",
      icon: <Icon path={mdiMapMarker} size={2} />,
      para: (
        <>
          Truzak Technology Inc. <br />
          180 Northfield Drive West, Suite 4, <br />
          Waterloo, Ontario, N2L 0C7, Canada
        </>
      ),
    },
    {
      title: "Phone",
      icon: <Icon path={mdiPhoneInTalk} size={2} />,
      para: (
        <>
          (647) 499-3787 <br /> (9:00am - 5:00pm Eastern Time) <br /> US/Canada
        </>
      ),
    },
    {
      title: "Email",
      icon: <Icon path={mdiEmail} size={2} />,
      para: "info@condozak.ca",
    },
  ];

  // React.useEffect(()=>{
  //   console.log(form.current);
  // },[])

  // const handleSubmit = user_mail_data => {
  //   console.log(user_mail_data);

  //   axios
  //     .post("http://localhost:4000/api/auth/sendmail", {
  //       email: user_mail_data.email,
  //       message: user_mail_data.message,
  //       firstname: user_mail_data.first_name,
  //       lastname: user_mail_data.last_name,
  //       companyname: user_mail_data.company_name,
  //       phone: user_mail_data.phone
  //     })
  //     .then(res => {
  //       console.log(res.data);
  //       toast.success("Email Sent.", {theme: "colored"});
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       toast.error("Something went wrong!.", {theme: "colored"});
  //     });
  // };

  const sendEmail = (e) => {
    // console.log(form.current);

    e.preventDefault();
    // setLoader(true);

    let errors = {};
    let vals = { ...formData };
    if (!vals.first_name) errors.first_name = "First Name is required!";
    if (!vals.last_name) errors.last_name = "Last Name is required!";
    if (!vals.email) errors.email = "Email is required!";
    if (!vals.company_name) errors.company_name = "Company Name is required!";
    if (!vals.phone) errors.phone = "phone is required!";
    setFieldErrors({ ...errors });

    if (Object.keys(errors).length > 0) {
      // console.log(errors);
      // setLoader(false);
      toast.error("Please correct above errors!", { theme: "colored" });
      return;
    }

    // console.log(form);

    emailjs
      .sendForm(
        "service_xpqo9vl",
        "template_vdwpu7h",
        form.current,
        "Y8ATuGO2CeFmtgEeA"
      )
      .then(
        (result) => {
          console.log(result);
          // setLoader(false);
          toast.success("Message Sent.", { theme: "colored" });
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            company_name: "",
            phone: "",
            message: "",
          });
          setFieldErrors({
            first_name: "",
            last_name: "",
            email: "",
            company_name: "",
            phone: "",
            message: "",
          });
        },
        (error) => {
          console.log(error);
          toast.error("Something went wrong!", { theme: "colored" });
        }
      );
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <div className="container">
        <PageHeader
          preTitle="We are here to help"
          title="Contact us"
          description="Whether you have a question, concern, or feedback, please feel free to contact us through any of the following channels:"
          className="mb-3"
          titleTag="h2"
        >
          <Link className="btn btn-sm btn-link ps-0" to="/contact-us">
            Have questions? Chat with us
          </Link>
        </PageHeader>
      </div>
      <Container className=" p-5" style={{}}>
        <Row>
          {infoBarArr.map((v, i) => (
            <Col key={i} md={4} className="text-center">
              <div>
                <div className="mb-3">{v.icon}</div>
              </div>
              <div>
                <h4>{v.title}</h4>
              </div>
              <div>
                <p className="text-left">{v.para}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      <br />
      <br />
      <div className="bg-white">
        <Container className="p-5" style={{}}>
          <div>
            <h4 className="text-center mb-3 mt-3 ">Lets get in touch.</h4>
          </div>
          <br />

          <Row>
            <Col md={6} className="text-center">
              <div>
                <div
                  style={{ width: "100%" }}
                  className="py-3 pb-3 scrollbar overflow-scroll"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2283.7344940143275!2d-80.54980622474086!3d43.49737507110987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882bf17ae34cfce3%3A0xd6a9c62b858ce5a!2sTruzak%20Technology%20Inc.!5e1!3m2!1sen!2s!4v1723463418665!5m2!1sen!2s"
                    width="500"
                    height="550"
                    style={{ border: "0", borderRadius: "5px" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </Col>

            <Col md={6} className="p-3">
              {/* <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  company_name: "",
                  email: "",
                  phone: "",
                  message: ""
                }}
                validationSchema={Yup.object({
                  first_name: Yup.string("Enter valid value!").required(
                    "First Name is required!"
                  ),
                  last_name: Yup.string("Enter valid value!").required(
                    "Last Name is required!"
                  ),
                  company_name: Yup.string("Enter valid value!").required(
                    "Company Name is required!"
                  ),
                  email: Yup.string("Enter valid value!")
                    .email("Must be valid email!")
                    .required("Email is required!"),
                  phone: Yup.string("Enter valid value!").required(
                    "Phone number is required!"
                  ),
                  message: Yup.string("Enter valid value!")
                })}
                onSubmit={(values, {setSubmitting}) => {
                  // setTimeout(() => {
                  // alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                  handleSubmit(values);
                  // sendEmail(values);
                  // }, 400);
                }}
              >
                {({isSubmitting, handleChange, handleBlur}) => (
                  <Form>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>
                        First Name <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field name="first_name" className="form-control" />
                      <ErrorMessage
                        name="first_name"
                        component={"div"}
                        className="text-danger fs--1 "
                      />
                    </BSForm.Group>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>
                        Last Name <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field name="last_name" className="form-control" />
                      <ErrorMessage
                        name="last_name"
                        component={"div"}
                        className="text-danger fs--1 "
                      />
                    </BSForm.Group>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>
                        Company Name <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field name="company_name" className="form-control" />
                      <ErrorMessage
                        name="company_name"
                        component={"div"}
                        className="text-danger fs--1 "
                      />
                    </BSForm.Group>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>
                        Email <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field name="email" className="form-control" />
                      <ErrorMessage
                        name="email"
                        component={"div"}
                        className="text-danger fs--1 "
                      />
                    </BSForm.Group>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>
                        Phone Number <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field name="phone" className="form-control" />
                      <ErrorMessage
                        name="phone"
                        component={"div"}
                        className="text-danger fs--1 "
                      />
                    </BSForm.Group>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>
                        Message <span className="text-danger"></span>
                      </BSForm.Label>
                      <BSForm.Control
                        as={"textarea"}
                        rows={3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="message"
                      />
                      <ErrorMessage
                        name="message"
                        component={"div"}
                        className="text-danger fs--1 "
                      />
                    </BSForm.Group>
                    <br />
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik> */}

              <div right>
                <form ref={form} onSubmit={sendEmail}>
                  {[
                    { name: "First Name", key: "first_name" },
                    { name: "Last Name", key: "last_name" },
                    { name: "Email Address", key: "email" },
                    { name: "Company Name", key: "company_name" },
                    { name: "Phone #", key: "phone" },
                    { name: "Message", key: "message" },
                  ].map((v, i) => (
                    <Form.Group key={i + 3214} className="mb-3">
                      <Form.Label htmlFor={v.key}>{v.name}</Form.Label>
                      <Form.Control
                        name={v.key}
                        onChange={handleFieldChange}
                        value={formData[v.key]}
                        as={v.key === "message" ? "textarea" : "input"}
                        rows={3}
                      />
                      <div>
                        <p className="text-danger fs--1 ">
                          {fieldErrors[v.key]}
                        </p>
                      </div>
                    </Form.Group>
                  ))}
                  {/* <label htmlFor="first_name">First Name</label>
                <input type="text" name="first_name" />
                <label htmlFor="last_name">Last Name</label>
                <input type="text" name="last_name" />
                <label htmlFor="email">Email</label>
                <input type="email" name="email" />
                <label htmlFor="company_name">Company Name</label>
                <input type="text" name="company_name" />
                <label htmlFor="phone">Phone</label>
                <input type="number" name="phone" />
                <label htmlFor="message">Message</label>
                <textarea name="message" /> */}

                  <div className="text-end">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactUs;

// return (
//   <div>
//     <Container
//       className="bg-white shadow-sm p-3 mb-3"
//       style={{marginTop: "80px", borderRadius: "10px"}}
//     >
//       <Row>
//         <Col md={12}>
//           <div className="text-center">
//             <h3 className="">Contact Us</h3>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//     <Container
//       className="bg-white shadow-sm p-5 mb-3"
//       style={{borderRadius: "10px"}}
//     >
//       <Row>
//         <Col md={6} className="d-flex align-items-center">
//           <div className="text-left mb-3">
//             <h3 className="text-uppercase" style={{letterSpacing: "3px"}}>
//               We are here to help!
//             </h3>
//             <p className="" style={{lineHeight: "50px", fontSize: "1.5rem"}}>
//               Whether you have a question, <br /> concern, or feedback, <br />
//               please feel free to contact us <br /> through any of the
//               following channels:
//             </p>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="text-center mb-3">
//             <Image src={support} fluid />
//           </div>
//         </Col>
//       </Row>
//     </Container>
//     <Container className="bg-white mb-3 p-5" style={{borderRadius: "10px"}}>
//       {/* <Row>
//         <Col md={8} className="text-center d-flex align-items-center">
//           <Image src={cst} fluid />
//         </Col>
//         <Col md={4} className="fw-bold">
//           <Row className="mb-3 mt-3">
//             <Col>Headquarters</Col>
//             <Col>
//               4975 Southampton Dr Unit: 145 Mississauga, ON L5M 8C8 Canada
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col>Phone</Col>
//             <Col>
//               US/Canada (9:00am - 5:00pm Eastern Time): (647) 499-3787{" "}
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col>Email</Col>
//             <Col>info@condozak.ca </Col>
//           </Row>
//           <br />
//           <br />
//           <Row className="mb-3 lead text-primary">
//             <Col>
//               <p style={{lineHeight: "30px", fontFamily: "sans-serif"}}>
//                 Please include the name and address of the property management
//                 company or building you are associated with to help us better
//                 assist you. We value your feedback and will get back to you as
//                 soon as possible. Thank you for choosing CondoZak!
//               </p>
//             </Col>
//           </Row>
//         </Col>
//       </Row> */}
//       <Row>
//         {infoBarArr.map((v, i) => (
//           <Col key={i} md={4} className="text-center">
//             <div className="mb-3">{v.icon}</div>
//             <h4>{v.title}</h4>
//             <p>{v.para}</p>
//           </Col>
//         ))}
//       </Row>
//     </Container>

//     <Container
//       className="bg-white shadow-sm p-5 mb-3"
//       style={{borderRadius: "10px"}}
//     >
//       <Row className="mb-3">
//         <Col md={6}>
//           <Formik
//             initialValues={{
//               first_name: "",
//               last_name: "",
//               company_name: "",
//               email: "",
//               phone: "",
//               message: ""
//             }}
//             //   validate={values => {
//             //     const errors = {};
//             //     if (!values.email) {
//             //       errors.email = "Required";
//             //     } else if (
//             //       !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
//             //     ) {
//             //       errors.email = "Invalid email address";
//             //     }
//             //     return errors;
//             //   }}
//             validationSchema={Yup.object({
//               first_name: Yup.string("Enter valid value!").required(
//                 "First Name is required!"
//               ),
//               last_name: Yup.string("Enter valid value!").required(
//                 "Last Name is required!"
//               ),
//               company_name: Yup.string("Enter valid value!").required(
//                 "Company Name is required!"
//               ),
//               email: Yup.string("Enter valid value!")
//                 .email("Must be valid email!")
//                 .required("Email is required!"),
//               phone: Yup.string("Enter valid value!").required(
//                 "Phone number is required!"
//               ),
//               message: Yup.string("Enter valid value!")
//             })}
//             onSubmit={(values, {setSubmitting}) => {
//               setTimeout(() => {
//                 alert(JSON.stringify(values, null, 2));
//                 setSubmitting(false);
//               }, 400);
//             }}
//           >
//             {({isSubmitting, handleChange, handleBlur}) => (
//               <Form>
//                 {/* <div className="col-md-6">
//                   <label htmlFor="inputEmail4" className="form-label">
//                     Email
//                   </label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="inputEmail4"
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <label htmlFor="inputPassword4" className="form-label">
//                     Password
//                   </label>
//                   <input
//                     type="password"
//                     className="form-control"
//                     id="inputPassword4"
//                   />
//                 </div>
//                 <div className="col-12">
//                   <label htmlFor="inputAddress" className="form-label">
//                     Address
//                   </label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="inputAddress"
//                     placeholder="1234 Main St"
//                   />
//                 </div>
//                 <div className="col-12">
//                   <label htmlFor="inputAddress2" className="form-label">
//                     Address 2
//                   </label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="inputAddress2"
//                     placeholder="Apartment, studio, or floor"
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <label htmlFor="inputCity" className="form-label">
//                     City
//                   </label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="inputCity"
//                   />
//                 </div>
//                 <div className="col-md-4">
//                   <label htmlFor="inputState" className="form-label">
//                     State
//                   </label>
//                   <select id="inputState" className="form-select">
//                     <option selected>Choose...</option>
//                     <option>...</option>
//                   </select>
//                 </div>
//                 <div className="col-md-2">
//                   <label htmlFor="inputZip" className="form-label">
//                     Zip
//                   </label>
//                   <input type="text" className="form-control" id="inputZip" />
//                 </div> */}
//                 <BSForm.Group className="mb-3">
//                   <BSForm.Label>
//                     First Name <span className="text-danger">*</span>
//                   </BSForm.Label>
//                   <Field name="first_name" className="form-control" />
//                   <ErrorMessage
//                     name="first_name"
//                     component={"div"}
//                     className="text-danger fs--1 "
//                   />
//                 </BSForm.Group>
//                 <BSForm.Group className="mb-3">
//                   <BSForm.Label>
//                     Last Name <span className="text-danger">*</span>
//                   </BSForm.Label>
//                   <Field name="last_name" className="form-control" />
//                   <ErrorMessage
//                     name="last_name"
//                     component={"div"}
//                     className="text-danger fs--1 "
//                   />
//                 </BSForm.Group>
//                 <BSForm.Group className="mb-3">
//                   <BSForm.Label>
//                     Company Name <span className="text-danger">*</span>
//                   </BSForm.Label>
//                   <Field name="company_name" className="form-control" />
//                   <ErrorMessage
//                     name="company_name"
//                     component={"div"}
//                     className="text-danger fs--1 "
//                   />
//                 </BSForm.Group>
//                 <BSForm.Group className="mb-3">
//                   <BSForm.Label>
//                     Email <span className="text-danger">*</span>
//                   </BSForm.Label>
//                   <Field name="email" className="form-control" />
//                   <ErrorMessage
//                     name="email"
//                     component={"div"}
//                     className="text-danger fs--1 "
//                   />
//                 </BSForm.Group>
//                 <BSForm.Group className="mb-3">
//                   <BSForm.Label>
//                     Phone Number <span className="text-danger">*</span>
//                   </BSForm.Label>
//                   <Field name="phone" className="form-control" />
//                   <ErrorMessage
//                     name="phone"
//                     component={"div"}
//                     className="text-danger fs--1 "
//                   />
//                 </BSForm.Group>
//                 <BSForm.Group className="mb-3">
//                   <BSForm.Label>
//                     Message <span className="text-danger"></span>
//                   </BSForm.Label>
//                   <BSForm.Control
//                     as={"textarea"}
//                     rows={3}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     name="message"
//                   />
//                   <ErrorMessage
//                     name="message"
//                     component={"div"}
//                     className="text-danger fs--1 "
//                   />
//                 </BSForm.Group>
//                 <br />
//                 <div className="text-end">
//                   <button
//                     type="submit"
//                     className="btn btn-primary"
//                     disabled={isSubmitting}
//                   >
//                     Let's Meet up
//                   </button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </Col>
//         <Col md={6} className="text-center">
//           <Image src={contactus} fluid />
//         </Col>
//       </Row>
//     </Container>
//     <Container
//       className="bg-white shadow-sm p-5 mb-3"
//       style={{borderRadius: "10px"}}
//     >
//       <Row>
//         <Col>
//           <SimpleMap />
//         </Col>
//       </Row>
//     </Container>
//   </div>
// );
