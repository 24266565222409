// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from "../../assets/img/Features/09 CLOUD--Secure-Storage.png";
import team from "../../assets/img/Features/10 ORGANIZE-Easy-Share.png";
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "EASY",
    color: "danger",
    title: "Seamless Resident Experience",
    description:
      "Empower your residents with CONDOZAK's Resident Hub, providing easy and convenient access to all building-related information and services at their fingertips.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "CONVENIENCE",
    color: "info",
    title: "Empower Residents",
    description:
      "Simplify things for Unit Owners and Tenants with CONDOZAK's Resident Hub, allowing them to book amenities, submit maintenance requests, manage visitors, and view important announcements, all from the comfort of their own homes..",
    image: team,
    inverse: true
  }
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }
];
