import React from "react";
// import InvitePeople from "components/pages/miscellaneous/invite-people/InvitePeople";
// import Timeline from "./Timeline-2";
// import {Col, Container, Row, Image} from "react-bootstrap";
// import img1 from "../assets/img/illustrations/about_1.png";
// import img2 from "../assets/img/illustrations/about_1.png";
// import img3 from "../assets/img/illustrations/about_1.png";

import {moveToTop} from "../helpers/utils";
import {RotatingLines} from "react-loader-spinner";
// import ProcessesAbout from "./ProcessesAbout";
import PageHeader from "../common/PageHeader";
import { Link } from "react-router-dom";
import processListAnnouncements from "../processesDir/processListAnnouncements";
import Processes from "../Processes";
export default function AnnouncementsaAndBulletins() {
  const [loader, setLoader] = React.useState(false);
  console.log(setLoader);

  // let aboutSection = [
  //   {
  //     para: "Welcome to CondoZak, your all-in-one property management solution. We are a team of passionate software developers and property management experts who are dedicated to helping property managers and residents streamline their tasks, save time, and achieve better results. Our software solutions are designed to simplify property management and enhance communication between property managers and residents.",
  //     img: img1
  //   },
  //   {
  //     para: "Our team has years of experience in the property management industry, which gives us a unique perspective on the challenges that come with managing properties of all sizes. We understand the importance of providing exceptional service to residents, while also managing costs for property owners. That's why we created CondoZak - to provide a comprehensive, intuitive, and customizable solution that meets the needs of property managers, unit owners, and residents alike.",
  //     img: img2
  //   },
  //   {
  //     para: "At CondoZak, we are committed to delivering value and excellence in everything we do. We are constantly innovating and improving our software, using the latest technologies and best practices to ensure that our clients have access to the most advanced tools and features available. Whether you're looking to automate your maintenance requests, manage finances more effectively, or enhance communication with your residents, CondoZak has the solution for you. Thank you for choosing CondoZak - we look forward to helping you succeed in your property management goals.",
  //     img: img3
  //   }
  // ];

  React.useEffect(() => {
    moveToTop();
    // setLoader(true);
    // setTimeout(() => {
    //   setLoader(false);
    // }, 350);
  }, []);

  if (loader)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{minHeight: "100vh"}}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  return (
    <div className="container" style={{'marginTop':'80px'}}>
      {/* {aboutSection.map(({para, img}, i) => (
        <Container key={i} className=" mb-3" style={{borderRadius: "10px"}}>
          <Row>
            
            {i == 1 ? (
              <>
                <Col md={6} className="text-center">
                  <Image src={img} width={"300px"} />
                </Col>
                <Col md={6} className="d-flex align-items-center">
                  <h5 style={{lineHeight: "40px"}}>{para}</h5>
                </Col>
              </>
            ) : (
              <>
                
                <Col md={6} className="d-flex align-items-center">
                  <h5 style={{lineHeight: "40px"}}>{para}</h5>
                </Col>
                <Col md={6} className="text-center">
                  <Image src={img} width={"300px"} />
                </Col>
              </>
            )}
          </Row>
        </Container>
      ))} */}
      <PageHeader
        // preTitle="About Condozak"
        title="Announcements"
        description="Effortlessly communicate important notices and updates to your residents with CONDOZAK Announcements."
        className="mb-3"
        titleTag="h2"
      >
        <Link className="btn btn-sm btn-link ps-0" to="/contact-us">
          Have questions? Chat with us
        </Link>
      </PageHeader>
      {/* <ProcessesAbout /> */}
      <Processes processList={processListAnnouncements} headerDisable/>
    </div>
  );
}
