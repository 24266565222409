
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/Features/03 GRANT-ACCESS.png';
import team from '../../assets/img/Features/04 OPEN-SESAME.png';
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "GRANT ACCESS",
    color: "danger",
    title: "Secure Visitor Management",
    description:
      "Visitor Management is an essential feature for any condo or building. With CONDOZAK, managing visitors is made simple, secure, and efficient. With our platform, staff can screen, track, and audit visitors in real-time, capturing photos and issuing visitor badges, generating an audit trail of all visitors passing through your property.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "OPEN SESAME",
    color: "info",
    title: "Residents Register Guests",
    description:
      "With CONDOZAK's Visitor Management feature, managing guests has never been easier. In addition to safety, CONDOZAK Visitor Management also streamlines the check-in process. Residents can simpally register guests, making it easier for staff to verify that a guest has permission to enter a unit. Residents can even share a photo of the guest, which eliminates any confusion or uncertainty about who should be granted entry.",
    image: team,
    inverse: true
  },
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }

];
