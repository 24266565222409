
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/Features/05 REDUCE-COSTS.png';
import team from '../../assets/img/Features/06 AUTOMATE--Comprehensive.png';
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "REDUCE COSTS",
    color: "danger",
    title: "Streamline Violations Tracking",
    description:
      "Tired of managing building violations manually? CONDOZAK's Violations Tracking feature takes the hard work out of the process and helps you manage your building or HOA better. With Violations Tracking, you can easily record, track, manage, and report violations, streamlining the process and reducing your enforcement costs.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "AUTOMATE",
    color: "info",
    title: "Comprehensive Violation Management",
    description:
      "Publish your rules and regulations, track violations, automate notices, and collect fines all within CONDOZAK. With our comprehensive violation tracking, you'll have all the information you need to manage your community effectively. Our resident portal makes it easy for homeowners to view and respond to their tickets, make payments, and upload files or photos.",
    image: team,
    inverse: true
  },
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }

];
