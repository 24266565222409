import {
  mdiCreditCardCheck,
  mdiPackageVariantClosedCheck,
  mdiCalculator
} from "@mdi/js";

export default [
  {
    // media: {icon: ["fab", "sass"], color: "info", className: "fs-4"},
    media: {icon: mdiCreditCardCheck, color: "info"},
    title: "Payment Processor",
    description:
      "CONDOZAK's Stripe integration with the resident portal simplifies payments for residents, allowing for convenient transactions including amenity reservations, event fees, and maintenance charges."
  },
  {
    // media: {icon: ["fab", "node-js"], color: "success", className: "fs-5"},
    media: {icon: mdiPackageVariantClosedCheck, color: "success"},
    title: "Package Management",
    description:
      "Stay tuned for our upcoming integrations with industry-leading package management solutions like Package Concierge, Luxer One, and Parcel Pending, to offer you the best package management experience."
  },
  {
    // media: {icon: ["fab", "gulp"], color: "danger", className: "fs-6"},
    media: {icon: mdiCalculator, color: "danger"},
    title: "Accounting Integrations",
    description:
      "With the ability to integrate with leading accounting software such as Yardi, QuickBooks, FreshBooks, and many more, CONDOZAK streamlines financial management processes and ensures data accuracy and reliability."
  }
];
