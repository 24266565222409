import React, {useRef} from "react";
import PageHeader from "../common/PageHeader";
import {Card, Col, Nav, Row} from "react-bootstrap";
import Account from "./Account";
import Subscription from "./Subscription";
import Termination from "./Termination";
import Rules from "./Rules";
import Liability from "./Liability";
// import Instructions from "./Instructions";
import Rights from "./Rights";
import useScrollSpy from "react-use-scrollspy";

const PrivacyPolicy = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const customTitle = (
    <div className="d-flex justify-content-between align-items-center">
      <span>Privacy policy</span>
      <span className="fs--1 text-secondary">Last Updated: 23 October, 2022</span>
    </div>
  );

  return (
    <div className="container" style={{'marginTop':'80px'}}>
      <PageHeader
        title={customTitle}
        description='The MailBluster service (the "Service") is a marketing service
        offered through the URL MailBluster.com (including any
        subdomains), our campaign sites and our email websites (together
        referred to as the "Website") that allows you to create, send and
        manage bulk email messages to individual recipients or
        subscribers. Each such email message in respect of which you use
        the Service, including the image, text, and code comprised in it,
        is referred to in these Terms as "Content"). MailBluster is owned
        and operated by.'
        className="mb-3"
        // fromPP={true}
      />
      <Row className="g-0">
        <Col lg={4} className="pe-lg-2">
          <div className="sticky-sidebar">
            <Card className="">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">On this page</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  <Nav.Item>
                    <Nav.Link
                      href="#account"
                      className="nav-link px-0 py-1"
                      active={activeSection === 0}
                    >
                      Account
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#subscriptions"
                      className="nav-link px-0 py-1"
                      active={activeSection === 1}
                    >
                      Subscriptions & Payments
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#rules"
                      className="nav-link px-0 py-1"
                      active={activeSection === 2}
                    >
                      Rules and abuse
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#termination"
                      className="nav-link px-0 py-1"
                      active={activeSection === 3}
                    >
                      Termination
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#liability"
                      className="nav-link px-0 py-1"
                      active={activeSection === 4}
                    >
                      Liability
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#rights"
                      className="nav-link px-0 py-1"
                      active={activeSection === 5}
                    >
                      Rights
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      href="#instructions"
                      className="nav-link px-0 py-1"
                      active={activeSection === 6}
                    >
                      Instructions
                    </Nav.Link>
                  </Nav.Item> */}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col lg={8} className="ps-lg-2">
          <Account ref={sectionRefs[0]} />
          <Subscription ref={sectionRefs[1]} />
          <Rules ref={sectionRefs[2]} />
          <Termination ref={sectionRefs[3]} />
          <Liability ref={sectionRefs[4]} />
          <Rights ref={sectionRefs[5]} />
          {/* <Instructions ref={sectionRefs[6]} /> */}
        </Col>
      </Row>
    </div>
  );
};

export default PrivacyPolicy;
