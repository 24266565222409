
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/Features/01 GUEST-CARS.png';
import team from '../../assets/img/Features/02 SPOTS.png';
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "GUEST CARS",
    color: "danger",
    title: "Visitor Parking Permits",
    description:
      "With CONDOZAK's Parking Permit feature, residents can easily create and process their own visitor parking permits through the portal. Our system settings ensure parking policies and rules are enforced, including time limits and restrictions on the number of passes issued per resident.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "SPOTS",
    color: "info",
    title: "Visitor Parking Monitoring",
    description:
      "Security guards and concierges can keep track of permits issued and monitor visitor parking to enforce rules and regulations. Each visitor parking permit submission is saved in the database and can be accessed for reference if needed.",
    image: team,
    inverse: true
  },
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }

];
