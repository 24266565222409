// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from "../../assets/img/Features/09 CLOUD--Secure-Storage.png";
import team from "../../assets/img/Features/10 ORGANIZE-Easy-Share.png";
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "CLOUD",
    color: "danger",
    title: "Secure Storage",
    description:
      "Effortlessly manage and store all of your building's important documents in one centralized location with CONDOZAK's Document Drive. With simple permissions-based access, customizable reminders and alerts, and powerful search capabilities, you'll have everything you need at your fingertips.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "ORGANIZE",
    color: "info",
    title: "Easy Sharing",
    description:
      "Say goodbye to scattered paper documents and hello to a secure and streamlined solution with CONDOZAK's Document Drive. Store, organize, and share large files with ease, set reminders for key dates and deadlines, and enjoy peace of mind knowing your important documents are always just a click away.",
    image: team,
    inverse: true
  }
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }
];
