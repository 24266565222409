export const bgWhiteIcons = [
  {
    icon: ["fab", "facebook-f"],
    color: "facebook",
    bg: "white",
    href: "https://www.facebook.com/profile.php?id=100091535696894",
    type: "icon",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
      </svg>
    ),
    color: "twitter",
    bg: "white",
    href: "https://x.com/condozak",
    type: "custom",
  },
  // {
  //   icon: ["fab", "google-plus-g"],
  //   color: "google-plus",
  //   bg: "white",
  //   href: "#!"
  // },
  {
    icon: ["fab", "linkedin-in"],
    color: "linkedin",
    bg: "white",
    href: "https://www.linkedin.com/company/condozak/",
    type: "icon",
  },
  // {icon: ["fab", "medium-m"], color: "gray-700", bg: "white", href: "#!"}
];
export default [
  {
    icon: ["fab", "facebook-f"],
    color: "facebook",
    href: "https://www.facebook.com/profile.php?id=100091535696894",
    type: "icon",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
      </svg>
    ),
    color: "twitter",
    href: "https://x.com/condozak",
    type: "custom",
  },
  // <i class="fa-brands fa-x-twitter"></i>
  // <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
  // {
  //   icon: ["fab", "google-plus-g"],
  //   color: "google-plus",
  //   href: "#!"
  // },
  {
    icon: ["fab", "linkedin-in"],
    color: "linkedin",
    href: "https://www.linkedin.com/company/condozak/",
    type: "icon",
  },
  // {icon: ["fab", "medium-m"], color: "gray-700", href: "#!"}
];
