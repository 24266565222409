import React from "react";
// import {Link} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
// import {useTranslation} from "react-i18next";
import "./FeaturesListNav.css";
// import Tooltip from "react-bootstrap/Tooltip";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const FeaturesListNav = () => {
  // const {t} = useTranslation();

  let featursList = [
    // {name: "Accounting Integrations", to: "/features/accounting-integration"},
    {name: "Amenity Bookings", to: "/amenity-bookings"},
    {name: "Announcements", to: "/announcements"},
    {name: "Bulletin Board", to: "/bulletin-board"},
    //{name: "Complaints", to: "/complaints"},
    //{name: "e-Consent ", to: "/e-consent"},
    {name: "Events Calendar", to: "/events-calendar"},
    {name: "Document Drive", to: "/document-drive"},
    {name: "Maintenance Requests", to: "/maintenance-requests"},
    // {name: "Online Payments", to: "/online-payments"},
    {name: "Resident Directory", to: "/resident-directory"},
    // {name: "Package Management", to: "/package-management"},
    // {name: "Portfolio Management", to: "/portfolio-management"},
    // {name: "Resident Hub", to: "/resident-hub"},
    {name: "Violations Tracking", to: "/violations-tracking"},
    {name: "Visitor Management", to: "/visitor-management"},
    {name: "Visitor Parking Permit", to: "/visitor-parking-permit"}
  ];

  // featursList = featursList.map(v => {
  //   return {name: v, to: "/features/amenity-bookings"};
  // });

  return (
    <div>
      <Container>
        <Row className="">
          {/* <Col sm={4} md={6} lg={6}> */}
          <Col>
            <ul
              className="text-dark cus-ul"
              style={{
                // lineHeight: "20px",
                cursor: "pointer",
                listStyleType: "none"
              }}
            >
              {/* <li style={{listStyleType: "none"}} className="text-primary">
              <span className="text-dark" style={{fontSize: "15px"}}>
                <FontAwesomeIcon icon={faMicrophone} />{" "}
              </span>
              Communication
            </li> */}
              {/*  */}

              {featursList.map((v, i) => (
                <li key={i + '321'}>
                  <a
                    href={v.to}
                    className="cool-link"
                    onClick={() => (window.location.href = v.to)}
                  >
                    <span className="h3"></span>{" "}
                    {/* {t("features.amenity_bookings")} */}
                    {v.name}
                  </a>
                </li>
              ))}

              {/* <li>
                <Link to={"/features/amenity-bookings"} className="cool-link">
                  <span className="h3"></span> {t("features.amenity_bookings")}
                </Link>
              </li>

              <li>
                <Link
                  to={"/features/announcements-and-bulletin"}
                  className="cool-link"
                >
                  {t("features.announcements_and_bulletins")}
                </Link>
              </li>

              <li>
                <Link to={"/features/complaints"} className="cool-link">
                  {t("features.complaints")}
                </Link>
              </li>

              <li>
                <Link to={"/features/events-calendar"} className="cool-link">
                  {t("features.events_calendar")}
                </Link>
              </li>

              <li>
                <Link to={"/features/file-library"} className="cool-link">
                  {t("features.file_library")}
                </Link>
              </li>

              <li>
                <Link to={"/features/mail-outs"} className="cool-link">
                  {t("features.mail_outs")}
                </Link>
              </li>

              <li>
                <Link to={"/features/online-payments"} className="cool-link">
                  {t("features.online_payments")}
                </Link>
              </li>

              <li>
                <Link to={"/features/owner-directory"} className="cool-link">
                  {t("features.owner_directory")}
                </Link>
              </li>

              <li>
                <Link to={"/features/violation-tracking"} className="cool-link">
                  {t("features.violation_tracking")}
                </Link>
              </li>

              <li>
                <Link to={"/features/visitor-management"} className="cool-link">
                  {t("features.visitor_management")}
                </Link>
              </li>

              <li>
                <Link
                  to={"/features/visitor-parking-permit"}
                  className="cool-link"
                >
                  {t("features.visitor_parking_permit")}
                </Link>
              </li>

              <li>
                <Link
                  to={"/features/accounting-integration"}
                  className="cool-link"
                >
                  {t("features.portfolio-managementmaintenance-trackingaccounting_integration")}
                </Link>
              </li>

              <li>
                <Link
                  to={"/features/portfolio-managementmaintenance-tracking"}
                  className="cool-link"
                >
                  {t("features.maintenance_tracking")}
                </Link>
              </li>

              <li>
                <Link
                  to={"/features/portfolio-management"}
                  className="cool-link"
                >
                  {t("features.portfolio_management")}
                </Link>
              </li> */}
            </ul>
          </Col>

          {/* <Col sm={4} md={6} lg={6}>
            <ul className='text-dark' style={{'lineHeight':'21px','cursor':'pointer','listStyleType':'none'}}>
                <li style={{'listStyleType':'none'}} className='text-primary'>
                  <span className='text-dark'><FontAwesomeIcon icon={faBook} /> </span>
                  Record Keeping
                  </li>
                
                <li className='cool-link'>Property Accounting</li>
                <li className='cool-link'>Online Payments</li>
                <li className='cool-link'>Maintance Requests</li>
                <li className='cool-link'>Resident Center</li>
                <li className='cool-link'>Tenant Screening</li>
                <li className='cool-link'>eSignature</li>
                <li className='cool-link'>Property Management Website</li>
                <li className='cool-link'>Market Place</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
            </ul>
        </Col> */}

          {/*  */}
          {/* <Col sm={4} md={6} lg={6}>
            <ul className='text-dark' style={{'lineHeight':'21px','cursor':'pointer','listStyleType':'none'}}>
                <li style={{'listStyleType':'none'}} className='text-primary'>
                  <span className='text-dark'><FontAwesomeIcon icon={faHammer} /> </span>
                  Security
                  </li>
                
                <li className='cool-link'>Property Accounting</li>
                <li className='cool-link'>Online Payments</li>
                <li className='cool-link'>Maintance Requests</li>
                <li className='cool-link'>Resident Center</li>
                <li className='cool-link'>Tenant Screening</li>
                <li className='cool-link'>eSignature</li>
                <li className='cool-link'>Property Management Website</li>
                <li className='cool-link'>Market Place</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
            </ul>
        </Col>
        <Col sm={4} md={6} lg={6}>
            <ul className='text-dark' style={{'lineHeight':'21px','cursor':'pointer','listStyleType':'none'}}>
                <li style={{'listStyleType':'none'}} className='text-primary'>
                  <span className='text-dark'><FontAwesomeIcon icon={faStar} /> </span>
                  Other Benefits
                  </li>
                
                <li className='cool-link'>Property Accounting</li>
                <li className='cool-link'>Online Payments</li>
                <li className='cool-link'>Maintance Requests</li>
                <li className='cool-link'>Resident Center</li>
                <li className='cool-link'>Tenant Screening</li>
                <li className='cool-link'>eSignature</li>
                <li className='cool-link'>Property Management Website</li>
                <li className='cool-link'>Market Place</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
                <li className='cool-link'>Unit Booking</li>
            </ul>
        </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default FeaturesListNav;
