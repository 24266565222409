import React from "react";
// import InvitePeople from "components/pages/miscellaneous/invite-people/InvitePeople";
// import Timeline from "./Timeline-2";
// import {Col, Container, Row, Image} from "react-bootstrap";
// import img1 from "../assets/img/illustrations/about_1.png";
// import img2 from "../assets/img/illustrations/about_1.png";
// import img3 from "../assets/img/illustrations/about_1.png";

// import {moveToTop} from "helpers/utils";
// import {RotatingLines} from "react-loader-spinner";
// import ProcessesAbout from "./ProcessesAbout";
import PageHeader from "./common/PageHeader";
import {Link} from "react-router-dom";
import processListAbout from "./processesDir/processListAbout";
import Processes from "./Processes";
// import Loader from "./common/Loader";
export default function AboutCondoZak() {
  // const [loader, setLoader] = React.useState(false);

  // React.useEffect(() => {
  //   moveToTop();
  //   setLoader(true);
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 350);
  // }, []);

  // if (loader) return <Loader />;
  return (
    <div className="container" style={{marginTop: "80px"}}>
      {/* {aboutSection.map(({para, img}, i) => (
        <Container key={i} className=" mb-3" style={{borderRadius: "10px"}}>
          <Row>
            
            {i == 1 ? (
              <>
                <Col md={6} className="text-center">
                  <Image src={img} width={"300px"} />
                </Col>
                <Col md={6} className="d-flex align-items-center">
                  <h5 style={{lineHeight: "40px"}}>{para}</h5>
                </Col>
              </>
            ) : (
              <>
                
                <Col md={6} className="d-flex align-items-center">
                  <h5 style={{lineHeight: "40px"}}>{para}</h5>
                </Col>
                <Col md={6} className="text-center">
                  <Image src={img} width={"300px"} />
                </Col>
              </>
            )}
          </Row>
        </Container>
      ))} */}
      <PageHeader
        // preTitle="About Condozak"
        title="About Condozak"
        description="Discover the story behind CONDOZAK and how we use technology to revolutionize property management."
        className="mb-3"
        titleTag="h2"
      >
        <Link className="btn btn-sm btn-link ps-0" to="/contact-us">
          Have questions? Chat with us
        </Link>
      </PageHeader>
      {/* <ProcessesAbout /> */}
      <Processes processList={processListAbout} headerDisable />
    </div>
  );
}
