// import google from 'assets/img/logos/google.png';
// import netflix from 'assets/img/logos/netflix.png';
// import amazon from 'assets/img/logos/paypal.png';
import bw2 from '../../../assets/img/logos/b&w/atlas-cl.png';
import bw4 from '../../../assets/img/logos/b&w/haven-cl.png';
import bw5 from '../../../assets/img/logos/b&w/urban-cl.png';

export default [
  {
    description:
      "I was initially hesitant about using a property management software, but CONDOZAK has exceeded my expectations. The interface is user-friendly and the customer support team is always responsive and helpful.",
    author: 'Matthew Lawson',
    designation: 'Property Manager',
    companyImage: bw2,
    size: 45
  },
  {
    description:
      "We've tried several property management solutions, but none compare to CONDOZAK. It has everything we need to streamline our operations and improve our resident satisfaction.",
    author: 'Daniel Mills',
    designation: 'Resident Services Coordinator',
    companyImage: bw4,
    size: 45
  },
  {
    description:
      "The automation features in CONDOZAK have allowed us to cut down on manual processes, freeing up more time to focus on other areas of our business. Highly recommended.",
    author: 'Eva Harrison',
    designation: 'Administrative Assistant',
    companyImage: bw5,
    size: 45
  }
];
