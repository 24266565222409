
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/illustrations/idea.png';
import team from '../../assets/img/illustrations/team.png';
import work from '../../assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "IDEA",
    color: "danger",
    title: "Coffee Shop Meeting",
    description:
      "It was a Friday afternoon in October 2020 when two old friends were catching up over coffee when they came up with the idea of simplifying property management through technology, leading to the birth of CONDOZAK.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "TEAM",
    color: "info",
    title: "Brains Behind Condozak",
    description:
      "We are a team of passionate software developers and property management experts who are dedicated to helping property managers and residents streamline their tasks, save time, and achieve better results.",
    image: team,
    inverse: true
  },
  {
    icon: ["far", "paper-plane"],
    iconText: "SYSTEMS",
    color: "success",
    title: "Technology Stack",
    description:
      "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
    image: work
  }

];
