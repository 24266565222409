import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const Termination = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="termination" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Termination</h5>
      </Card.Header>
      <Card.Body>
        <p className="mb-0 ps-3">
          The MailBluster service (the "Service") is a marketing service offered
          through the URL MailBluster.com (including any subdomains), our
          campaign sites and our email websites). our campaign sites and our
          email websites).
        </p>
      </Card.Body>
    </Card>
  );
});

export default Termination;
