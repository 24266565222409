import freeIcon from "../../assets/img/icons/free.svg";
import proIcon from "../../assets/img/icons/pro.svg";

export const pricingData = [
  {
    id: 0,
    title: "Essential",
    subTitle:
      "Our most affordable option with the basic features you need to manage your property.",
    price: 0.5,
    // url: '/e-commerce/billing',
    // buttonText: 'Start free trial',
    isFeatured: false,
    featureTitle: "Every thing you need to get started",
    features: [
      {id: 1, title: "Amenity Bookings"},
      {id: 2, title: "Announcements"},
      // { id: 3, title: 'Custom fields', tag: { label: 'New', type: 'success' } },
      {id: 3, title: "Bulletin Board"},
      {id: 4, title: "Events Calendar"},
      {id: 5, title: "Maintenance Requests"},
      {id: 6, title: "Resident Directory"},
      {id: 7, title: "Resident Portal"}
    ]
  },
  {
    id: 1,
    title: "Advanced",
    subTitle:
      "Our most popular plan that includes advanced features to streamline operations and enhance resident satisfaction.",
    price: 1.0,
    // url: '/e-commerce/billing',
    // buttonText: 'Get Advanced',
    isFeatured: true,
    featureTitle: "Everything in Essential, plus:",
    features: [
      {id: 1, title: "Document Drive"},
      {id: 2, title: "Violation Tracking"},
      {id: 3, title: "Visitor Management"},
      {id: 4, title: "Visitor Parking Permit"},
      {id: 5, title: "Online Payments"},
      {id: 6, title: "Support"}
    ]
  },
  {
    id: 2,
    title: "Ultimate",
    subTitle:
      "Our top-tier option with all the features and customization options we offer, plus priority support and dedicated account management.",
    price: 2.0,
    // url: '/e-commerce/billing',
    // buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: "Everything in Advanced, plus:",
    features: [
      {id: 1, title: "Bigger Document Drive"},
      // {id: 2, title: "Tags", tag: {label: "Coming soon", type: "primary"}},
      {id: 2, title: "Package Management"},
      {id: 3, title: "Accounting Intigrations"},
      {id: 4, title: "customization"},
      {id: 5, title: "Templates"},
      {id: 6, title: "Priority Support"}
    ]
  }
];

export const pricingAltData = [
  {
    id: 0,
    title: "Free",
    price: 0,
    period: "m",
    icon: freeIcon,
    url: "#!",
    buttonText: "Start free trial",
    isFeatured: false,
    features: [
      {id: 0, title: "Unlimited Broadcasts", isActive: true},
      {id: 1, title: "Unlimited Sequences", isActive: true},
      {id: 2, title: "Advanced marketing", isActive: true},
      {id: 3, title: "Api & Developer Tools", isActive: true},
      {id: 4, title: "Integrations", isActive: false},
      {id: 5, title: "Payments", isActive: false},
      {id: 6, title: "Unlimted Tags", isActive: false},
      {id: 7, title: "Custom Fields", isActive: false}
    ]
  },
  {
    id: 1,
    title: "Pro",
    price: 99,
    period: "m",
    icon: proIcon,
    url: "#!",
    buttonText: "Purchase Now",
    isFeatured: true,
    features: [
      {id: 0, title: "Unlimited Broadcasts", isActive: true},
      {id: 1, title: "Unlimited Sequences", isActive: true},
      {id: 2, title: "Advanced marketing", isActive: true},
      {id: 3, title: "Api & Developer Tools", isActive: true},
      {id: 4, title: "Integrations", isActive: true},
      {id: 5, title: "Payments", isActive: true},
      {id: 6, title: "Unlimted Tags", isActive: true},
      {id: 7, title: "Custom Fields", isActive: true}
    ]
  }
];
