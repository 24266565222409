// import classNames from "classnames";
// import React, { useContext, useEffect, useState } from 'react';
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {Container, Nav, Navbar} from "react-bootstrap";
// import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import LandingRightSideNavItem from "./LandingRightSideNavItem";
import {topNavbarBreakpoint} from "../config";
import AppContext from "../context/Context";
import FeaturesListNav from "./FeaturesListNav";
import NavbarDropdown from "./NavbarDropdown";
import {useTranslation} from "react-i18next";
// import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
// import Login from 'components/authentication/simple/Login';
import logo_cz_slogan from "../assets/img/illustrations/Condo Zak slogan only.png";

const NavbarStandard = () => {
  const {
    config: {isDark}
  } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleNavbarTransparency);
  //   return () => window.removeEventListener('scroll', handleNavbarTransparency);
  // }, []);

  const {t} = useTranslation();
  // console.log(isDark);
  return (
    <Navbar
      variant={"light"}
      fixed="top"
      expand={topNavbarBreakpoint}
      className={"bg-white navbar-standard navbar-theme"}
      // className={classNames("navbar-standard navbar-theme", {
      //   "bg-100": !navbarCollapsed && isDark,
      //   "bg-dark": !navbarCollapsed && !isDark
      // })}
      // style={{marginBottom: "50px", backgroundColor: "white", boxShadow:'box-shadow: 0 4px 2px -2px gray'}}
      style={{boxShadow: "0 5px 6px rgba(0,0,0,0.2)"}}
    >
      <Container className="">
        {/* <Navbar.Brand className="text-white dark__text-white" as={Link} to="/"> */}
        <Navbar.Brand className="" 
        // as={Link} 
        href="/">
          <img
            src={logo_cz_slogan}
            // width="30"
            height="30"
            className="d-inline-block align-end"
            alt="logo_cz_slogan"
          />
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Navbar.Collapse className="scrollbar">
          <Nav>
            <Nav.Link
              // as={Link}
              href="/about-condozak"
              className="nav_items text-secondary"
            >
              {/* {t("topbar.about_condozak")} */}
              About
            </Nav.Link>
            <NavbarDropdown title="Features">
              <FeaturesListNav />
            </NavbarDropdown>
            <Nav.Link
              as={Link}
              to="/#acc-integ"
              // onClick={
                // executeScroll
                // () => (window.location.hash = "#acc-integ")
                //   window.scrollTo({top: 2300, left: 0, behavior: "smooth"})
              // }
              className="nav_items text-secondary"
            >
              {/* {t("topbar.pricing")} */}
              Integrations
            </Nav.Link>
            <Nav.Link
              // as={Link}
              href="/pricing"
              className="nav_items text-secondary"
            >
              {t("topbar.pricing")}
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/contact-us" className="nav_items text-dark">
              {t('topbar.contact_us')}
            </Nav.Link> */}
          </Nav>
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
