// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from "../../assets/img/Features/11 UPKEEP-Streamline-Maintainance.png";
import team from "../../assets/img/Features/12 WORK-ORDERS--Service-Requests.png";
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "UPKEEP",
    color: "danger",
    title: "Streamline Maintenance",
    description:
      "With CONDOZAK's Maintenance Requests feature, management and maintenance staff can easily track, enter, and update all resident service requests, ensuring prompt and efficient maintenance of the building.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "WORK ORDERS",
    color: "info",
    title: "Service Requests",
    description:
      "Submitting service requests has never been easier with CONDOZAK. Residents can simply upload images and descriptions of their maintenance needs from their computer or phone, and management will receive an instant notification to take action.",
    image: team,
    inverse: true
  }
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }
];
