
import Communications from "../../assets/img/icons/spot-illustrations/latest/Communications.png";
import Recordkeeping from "../../assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
import ResidentPortal from "../../assets/img/icons/spot-illustrations/latest/Resident-Portal.png";

export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "RECORDS",
    color: "danger",
    title: "Record-Keeping",
    description:
      "Efficiently manage owner and unit information, amenity reservations, vehicle management, and document storage all in one place with CondoZak's Record-Keeping & Administration features.",
    image: Recordkeeping
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "RELAY",
    color: "info",
    title: "Communications",
    description:
      "CondoZak's Communications features help you keep your residents in the loop. Easily send custom mail-outs, post announcements and bulletins, and stay on top of community events, all in one place.",
    image: Communications,
    inverse: true
  },
  {
    icon: ["far", "paper-plane"],
    iconText: "DWELLERS",
    color: "success",
    title: "Resident Portal",
    description:
      "CondoZak's Resident Portal is the one-stop-shop for all your community needs. From booking amenities to submitting maintenance requests, paying bills, and staying informed with announcements and bulletins, everything is just a click away.",
    image: ResidentPortal
  }

];
