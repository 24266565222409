import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
 import { CloseButton } from "./components/common/Toast";
// import 'bootstrap/dist/css/bootstrap.min.css'
import "react-toastify/dist/ReactToastify.min.css";
import "./assets/css/theme.min.css";
import "./App.css";
import Hero from "./components/Hero";
import NavbarStandard from "./components/NavbarStandard";
import Partners from "./components/Partners";
import Processes from "./components/Processes";
import Services from "./components/Services";
import Testimonial from "./components/Testimonial";
// import Cta from "./components/Cta";
import FooterStandard from "./components/FooterStandard";

import "./i18n/config";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";

import VisitorManagement from "./components/features/VisitorManagement";
import OnlinePayments from "./components/features/OnlinePayments";
import AmenityBookings from "./components/features/AmenityBookings";
import AnnouncementsaAndBulletins from "./components/features/AnnouncementsaAndBulletins";
import Complaints from "./components/features/Complaints";
import EventsCalendar from "./components/features/EventsCalendar";
import FileLibrary from "./components/features/FileLibrary";
import MailOuts from "./components/features/MailOuts";
import OwnerDirectory from "./components/features/OwnerDirectory";
import ViolationTracking from "./components/features/ViolationTracking";
import VisitorParkingPermit from "./components/features/VisitorParkingPermit";
import ContactUs from "./components/ContactUs";
import Pricing from "./components/Pricing";
import AboutCondoZak from "./components/AboutCondoZak";
import AccountIntegration from "./components/features/AccountIntegration";
import MaintenanceTracking from "./components/features/MaintenanceTracking";
import PortfolioManagement from "./components/features/PortfolioManagement";

// import TermsAndPrivacy from "./components/TermsAndPrivacy/TermsAndPrivacy";
// import FixedLayout from "./components/TermsAndPrivacy/FixedLayout";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
// import BreadcrumbBanner from "./components/BreadcrumBanner";
import Error404 from "./components/errors/Error404";
// import SectionHeader from "components/pages/landing/SectionHeader";
// import PageHeader from "components/common/PageHeader";
// import {Link} from "react-router-dom";
// import {Container, Row, Col} from "react-bootstrap";
// import {element} from "prop-types";

// const setLang = e => {
//   i18next.changeLanguage(e.target.value);
// };
import processListHome from "./components/processesDir/processListHome";
import BulletinBoard from "./components/features/BulletinBoard";
import ResidentHub from "./components/features/ResidentHub";
import Loader from "./components/common/Loader";

const App = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(false);

  // const location = useLocation();
  // const handleLoading = () => {
  //   setLoader(false);
  // };
  const locationRouter = useLocation();

  // const [count, setCount] = React.useState(0);

  let integrationRef = useRef(null);

  const executeScroll = () => {
    if (locationRouter.pathname !== "/") {
      navigate("/");
      integrationRef.current.scrollIntoView();
    }
    integrationRef.current.scrollIntoView();
  };

  useEffect(() => {
    // window.addEventListener("load", handleLoading);
    // return () => window.removeEventListener("load", handleLoading);
    // console.log(location.pathname);
    // if (location.pathname !== "/errors/404") {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 500);
    // setCount(count + 1);
    // setLoader(true);
    // let tt = setTimeout(() => {
    //   setLoader(false);
    // },);
    // console.log(locationRouter.pathname);
    // return () => {
    //   clearTimeout(tt);
    // };
    // }
  }, []);

  // const handleLoader = () => {
  //   setLoader(true);
  // }

  let homeRoutes = [
    {
      path: "/",
      element: (
        <>
          <Hero />
          <Partners />
          <Processes
            processList={processListHome}
            heading="Property Management Software"
            para="All-in-one cloud-based web app for efficient record-keeping,
            streamlined administration, effective communications, and a powerful
            resident portal."
          />
          <Services integrationRef={integrationRef} />
          <Testimonial />
          {/* <Cta /> */}
        </>
      ),
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/about-condozak",
      element: <AboutCondoZak />,
    },
    {
      path: "/terms-and-privacy",
      element: <PrivacyPolicy />,
    },
    { path: "/amenity-bookings", element: <AmenityBookings /> },
    {
      path: "/announcements",
      element: <AnnouncementsaAndBulletins />,
    },
    {
      path: "/bulletin-board",
      element: <BulletinBoard />,
    },
    { path: "/complaints", element: <Complaints /> },
    { path: "/events-calendar", element: <EventsCalendar /> },
    { path: "/document-drive", element: <FileLibrary /> },
    { path: "/mail-outs", element: <MailOuts /> },
    { path: "/resident-directory", element: <OwnerDirectory /> },
    { path: "/violations-tracking", element: <ViolationTracking /> },
    {
      path: "/visitor-parking-permit",
      element: <VisitorParkingPermit />,
    },
    { path: "/visitor-management", element: <VisitorManagement /> },
    { path: "/online-payments", element: <OnlinePayments /> },
    { path: "/accounting-integration", element: <AccountIntegration /> },
    { path: "/maintenance-requests", element: <MaintenanceTracking /> },
    {
      path: "/portfolio-management",
      element: <PortfolioManagement />,
    },
    {
      path: "/resident-hub",
      element: <ResidentHub />,
    },
    {
      path: "/errors/404",
      element: <Error404 />,
    },
  ];
  // console.log(navigate);
  // sconst { t } = useTranslation();
  

  if (loader) return <Loader />;
  else
    return (
      <div className="main-font">
        {/* {locationRouter.pathname === "/terms-and-privacy" ? (
        ""
      ) : (
        )} */}
        <NavbarStandard executeScroll={executeScroll} />
        {/* {locationRouter.pathname !== "/" && (
        // <BreadcrumbBanner path={locationRouter.pathname} />
        <Container style={{marginTop: "80px"}}>
          <Row>
            <Col>
              <PageHeader
                // preTitle="Free for 30 days"
                title={
                  locationRouter.pathname === "/contact-us"
                    ? "We are here to help"
                    : "For teams of all sizes, in the cloud"
                }
                description={
                  locationRouter.pathname === "/contact-us"
                    ? "Whether you have a question, concern, or feedback, please feel free to contact us through any of the following channels:"
                    : "Get the power, control, and customization you need to manage your <br className='d-none.d-md-block' /> team’s and organization’s projects."
                }
                className="mb-3"
                titleTag="h2"
              >
                <Link className="btn btn-sm btn-link ps-0" to="/contact-us">
                  Have questions? Chat with us
                </Link>
                
              </PageHeader>
            </Col>
          </Row>
        </Container>
      )} */}
        <Routes>
          {homeRoutes.map((v, i) => (
            <Route key={i + 213} path={v.path} element={v.element} />
          ))}
          <Route path="*" element={<Error404 />} />
        </Routes>

        {locationRouter.pathname === "/terms-and-privacy" ||
        locationRouter.pathname === "/bnr" ? (
          ""
        ) : (
          <FooterStandard />
        )}

        {/* <SettingsToggle />
      <SettingsPanel /> */}
        <ToastContainer
          closeButton={CloseButton}
          icon={false}
          position={toast.POSITION.BOTTOM_LEFT}
        />
      </div>
    );
};

export default App;
