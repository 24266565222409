import bw1 from '../../../assets/img/logos/b&w/acme.png';
import bw3 from '../../../assets/img/logos/b&w/Cascadia.png';
import bw2 from '../../../assets/img/logos/b&w/atlas.png';
import bw4 from '../../../assets/img/logos/b&w/template.png';
import bw5 from '../../../assets/img/logos/b&w/urban.png';
// import bw2 from 'assets/img/logos/b&w/11.png';
// import bw3 from 'assets/img/logos/b&w/2.png';
// import bw4 from 'assets/img/logos/b&w/4.png';
// import bw5 from 'assets/img/logos/b&w/1.png';
// import bw6 from 'assets/img/logos/b&w/10.png';
// import bw7 from 'assets/img/logos/b&w/12.png';

export default [
  { src: bw1, height: 50 },
  { src: bw2, height: 50 },
  { src: bw3, height: 50 },
  { src: bw4, height: 50 },
  { src: bw5, height: 50 },
  // { src: bw6, height: 40 },
  // { src: bw7, height: 40 }
];
