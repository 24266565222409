import React from "react";
// import processList from "data/feature/processList";
import Section from "./common/Section";
import Process from "./Process";
// import SectionHeader from "./SectionHeader";
import { isIterableArray } from "./helpers/utils";
import { Row, Col } from "react-bootstrap";
// import {Fade} from "react-reveal";
const Processes = ({ processList, heading, para, headerDisable }) => {
  // console.log(processList);
  return (
    <Section className="">
      {/* <SectionHeader
        title="Property Management Software"
        subtitle="All-in-one cloud-based web app for efficient record-keeping, streamlined administration, effective communications, and a powerful resident portal."
      /> */}
      {!headerDisable && (
        <div>
          <Row className="justify-content-center text-center">
            <Col lg={8} xl={7} xxl={6} className="col-xxl-6">
              <h2 className="">{heading || "heading here"}</h2>
              <p className="text-left">{para || "paragraph will be here "}</p>
            </Col>
          </Row>
        </div>
      )}
      {isIterableArray(processList) &&
        processList.map((process, index) => (
          <Process
            key={process.color}
            headerDisable={headerDisable}
            isFirst={index === 0}
            {...process}
          />
        ))}
    </Section>
  );
};

export default Processes;
