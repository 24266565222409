
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/Features/03 Personalized-NOTIFICATIONS.png';
import team from '../../assets/img/Features/04 UPDATES.png';
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "NOTIFICATIONS",
    color: "danger",
    title: "Personalized Announcements",
    description:
      "Introducing the Announcements feature of CONDOZAK - an easy way for Property Managers to draft personalized notices and announcements to different groups of recipients, such as owners, residents, and board members, based on specific criteria like location, building, or floor. Stay in touch with your community like never before!",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "UPDATES",
    color: "info",
    title: "Stay Informed",
    description:
      "Residents can stay up to date with all the latest announcements and notices through CONDOZAK's easy-to-use platform, which eliminates the need for paper notices and helps reduce clutter. With personalized announcements, residents can rest assured they won't miss any important news or updates.",
    image: team,
    inverse: true
  },
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }

];
