import React from "react";
import { Row, Col } from "react-bootstrap";
import className from "classnames";
import serviceList from "./data/feature/serviceList";
import Section from "./common/Section";
import CardService from "./CardService";
import SectionHeader from "./SectionHeader";
import { useLocation } from "react-router-dom";
// import Fade from "react-reveal";
const Services = ({ integrationRef }) => {
  let location = useLocation();
  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView({ behavior: "smooth" });
        }, 300);
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div ref={integrationRef}>
      <Section bg="light" className="text-center" id="acc-integ">
        <div>
          <SectionHeader
            title="CONDOZAK Integrations"
            subtitle="At CONDOZAK, we provide seamless integration with our valued partners, enabling you to optimize your time and resources while delivering an exceptional user experience."
          />
        </div>
        <Row className="mt-6">
          {serviceList.map((service, index) => (
            <Col
              lg={4}
              className={className({ "mt-6 mt-lg-0": index > 0 })}
              key={index}
            >
              <CardService {...service} />
            </Col>
          ))}
        </Row>
      </Section>
    </div>
  );
};

export default Services;
