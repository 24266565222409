
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/Features/DIRECTORY-Streamlined--Resident--Management.png';
import team from '../../assets/img/Features/RECORDS--Secure-Unit-File.png';
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "DIRECTORY",
    color: "danger",
    title: "Streamlined Resident Management",
    description:
      "Easily manage all resident information with CONDOZAK's Resident Directory. From service requests to amenity reservations, this comprehensive platform provides property managers with quick search access to all unit and occupant-related details, ensuring streamlined operations and effective communication with residents.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "RECORDS",
    color: "info",
    title: "Secure Unit File",
    description:
      "Simplify the process of updating resident information with CONDOZAK's Resident Directory. Administrators can make changes to unit profiles when residents move in or out, ensuring accurate and up-to-date information for emergency contacts, vehicle details, pets, lockers, and more, while residents never miss important announcements or updates.",
    image: team,
    inverse: true
  }

];
