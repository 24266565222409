import React from "react";
// import PropTypes from "prop-types";
// import className from "classnames";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import Icon from "@mdi/react";
// import Fade from "react-reveal";
const CardService = ({ media, title, description, children }) => (
  <div>
    <Card
      className="card-span "
      style={{ minHeight: "260px" }}
    >
      <div className="card-span-img">
        {/* <FontAwesomeIcon
        icon={media.icon}
        className={className(
          {[`text-${media.color}`]: media.color},
          media.className
          )}
        /> */}
        <Icon path={media.icon} size={2} className={`text-${media.color}`} />
      </div>
      <Card.Body className="pt-6 pb-4">
        <h5 className="mb-2">{title}</h5>
        {description && <p>{description}</p>}
        {children}
      </Card.Body>
    </Card>
  </div>
);

// CardService.propTypes = {
//   media: PropTypes.shape({
//     icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
//     color: PropTypes.string.isRequired,
//     className: PropTypes.string
//   }),
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string,
//   children: PropTypes.node
// };

export default CardService;
