import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import PricingDefaultHeader from "./pricing/pricing-default/PricingDefaultHeader";
import PricingDefaultCard from "./pricing/pricing-default/PricingDefaultCard";
import useFakeFetch from "./hooks/useFakeFetch";
import { pricingData } from "./data/pricing";
import PageHeader from "./common/PageHeader";
// import Loader from "./common/Loader";
export default function Pricing() {
  const { loading: priceLoading, data: pricing } = useFakeFetch(
    pricingData,
    1000
  );
  // const [loader, setLoader] = React.useState(false);
  // React.useEffect(() => {
  //   setLoader(true);
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 500);
  // }, []);

  // if(loader)
  // return <Loader />
  return (
    <div className="container" style={{ marginTop: "80px" }}>
      <PageHeader
        // preTitle="Free for 30 days"
        title="Condozak Pricing"
        description="We offer competitive pricing plans that are designed to fit the unique needs of your property management."
        className="mb-3"
        titleTag="h2"
      >
        <Link className="btn btn-sm btn-link ps-0" to="/contact-us">
          Have questions? Chat with us
        </Link>
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            <PricingDefaultHeader />
            {priceLoading ? (
              <Col xs={12} className="py-4">
                <Spinner
                  className="position-absolute start-50"
                  animation="grow"
                />
              </Col>
            ) : (
              pricing.map((pricing) => (
                <PricingDefaultCard key={pricing.id} pricing={pricing} />
              ))
            )}
            <Col xs={12} className="text-center">
              <h5 className="mt-5 mb-4" style={{ lineHeight: "40px" }}>
                Our pricing plans are transparent and easy to understand.
                <br /> We believe in being upfront about our pricing, so there
                are no hidden fees or surprises.{" "}
              </h5>
              {/* <p className="fs-1">
                Try the <Link to="#!">basic version</Link> of Falcon
              </p> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
