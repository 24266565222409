
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/Features/01 Resident-BOOKINGS.png';
import team from '../../assets/img/Features/02 CREATE-AMENITIES.png';
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "BOOKINGS",
    color: "danger",
    title: "For Our Residents",
    description:
      "Residents can easily browse available amenities and book them online through CONDOZAK's Amenity Booking feature, making it a hassle-free process. With the ability to reserve different types of amenities such as party rooms, gyms, and service elevators, residents can enjoy the convenience of booking amenities at their own leisure.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "CREATE AMENITIES",
    color: "info",
    title: "For Propety Managers",
    description:
      "With Amenity Booking, property managers can easily create and manage amenities, view and approve bookings all in one place, making the process seamless and efficient. This allows them to focus on other important tasks while ensuring their residents have access to the amenities they need.",
    image: team,
    inverse: true
  },
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }

];
