
// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from '../../assets/img/Features/05 COMMUNITY-Connect.png';
import team from '../../assets/img/Features/06 MODERATE-Safe-&-Secure-Bulletin.png';
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "COMMUNITY",
    color: "danger",
    title: "Connect Easily",
    description:
      "Residents can engage with their community by buying, selling, or renting goods and services, requesting recommendations, or finding activity partners through CONDOZAK's Bulletin Board feature.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "MODERATE",
    color: "info",
    title: "Safe & Secure Bulletin",
    description:
      "With the option for property managers to moderate posts, the Bulletin Board feature on CONDOZAK provides a safe and secure space for residents to connect and engage with one another.",
    image: team,
    inverse: true
  },
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }

];
