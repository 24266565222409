import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Account = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="account" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Account</h5>
      </Card.Header>
      <Card.Body>
            <div className="border-bottom">
              <h5 className="text-primary">Eligibility</h5>
              <p className="fw-semibold">In order to use you must:</p>
              <p className="mt-3 text-justify">
                1. The MailBluster service (the "Service") is a marketing
                service offered through the URL MailBluster.com (including any
                subdomains), our campaign sites and our email websites (together
                referred to as the "Website") that allows.
              </p>
            </div>
            <br />
            <div className="border-bottom">
              <h5 className="text-primary">Accepting These Terms</h5>
              <p className="fw-semibold">In order to use you must:</p>
              <p className="mt-3 text-justify">
                The MailBluster service (the "Service") is a marketing service
                offered through the URL MailBluster.com (including any
                subdomains), our campaign sites and our email websites (together
                referred to as the "Website") that allows you to.
              </p>
            </div>
            <br />
            <div className="">
              <h5 className="text-primary">Closing your ACCOUNT</h5>
              <p className="fw-semibold">In order to use you must:</p>
              <p className="mt-3 text-justify">
                The MailBluster service (the "Service") is a marketing service
                offered through the URL MailBluster.com (including any
                subdomains), our campaign sites and our email websites (together
                referred to as the "Website") that allows you to create, send
                and manage bulk email messages to individual recipients or
                subscribers. Each such email message in respect of which you use
                the Service, including the image, text, and code comprised in
                it, is referred to in these Terms as "Content"). MailBluster is
                owned and operated by.
              </p>
            </div>
      </Card.Body>
    </Card>
  );
});

export default Account;
