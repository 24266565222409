import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bg1 from "../assets/img/bg-landing.jpg";

import dashboard from "../assets/img/dashboard-alt.png";
import Section from "./common/Section";
// import AppContext from "context/Context";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import { useTranslation } from "react-i18next";
import './Hero.css';
// import div from "react-reveal";
const Hero = () => {
  // const {
  //   config: {isDark, isRTL}
  // } = useContext(AppContext);

  const { t } = useTranslation();

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg1}
      // image=''
      position="center bottom"
      overlay
      style={{minHeight:'950px'}}
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0" style={{marginTop:'200px'}}>
        <Col
          md={12}
          lg={12}
          xl={12}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          {/* <h1 className="text-white fw-bold">
            CondoZak
          </h1> */}
          <div>
            <h3 className="text-white fw-light">
              {t("landing_section.bring")}{" "}
              <span className="fw-bold">
                <Typewriter
                  words={[
                    "Efficiency",
                    "Innovation",
                    "Flexibility",
                    "Simplicity",
                  ]}
                  loop={false}
                  cursor={true}
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
              <br />
              {t("landing_section.to_your_management")}
            </h3>
          </div>
          <div>
            <p className="lead text-white opacity-75" style={{width:'400px'}}>
              {t("landing_section.para_1")}
            </p>
          </div>
          <div>
            <Button
              as={Link}
              variant="outline-light"
              size="lg"
              className="border-2 rounded-pill mt-4 fs-0 py-2"
              to="/contact-us"
            >
              {/* {t('landing_section.get_started_using_condozak')} */}
              Request a Demo
              <FontAwesomeIcon
                icon="play"
                transform="shrink-6 down-1 right-5"
              />
            </Button>
          </div>
        </Col>
        {/* <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-end mt-xl-0"
        >
          <Link to="/" className="img-landing-banner">
            <div className="change-opacity">
              <img className="img-fluid" src={dashboard} alt="" />
            </div>
          </Link>
        </Col> */}
      </Row>
    </Section>
  );
};

export default Hero;
