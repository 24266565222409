import React from "react";
import PropTypes from "prop-types";
import Section from "./common/Section";
import IconGroup from "./common/icon/IconGroup";
import {Row, Col} from "react-bootstrap";
// import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bgWhiteIcons} from "./data/socialIcons";
// import { version } from 'config';
import {useTranslation} from "react-i18next";

const FooterTitle = ({children}) => (
  <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = {children: PropTypes.node.isRequired};

const FooterStandard = () => {
  // const navigate = useNavigate();
  const moveToPath = path => {
    // navigate(path);
    window.location.href = path;
  };
  // const footerlinks = [
  //   {
  //     element: t("footer.about"),
  //     path: "/about-condozak",
  //     move: path => moveToPath(path)
  //   },
  //   {
  //     element: t("footer.about"),
  //     path: "/about-condozak",
  //     move: path => moveToPath(path)
  //   }
  // ];

  let forCompany = [
    {
      label: "Home",
      to: "/"
    },
    {
      label: "About",
      to: "/about-condozak"
    },
    {
      label: "Integrations",
      to: "/#acc-integ"
    },
    {
      label: "Pricing",
      to: "/pricing"
    },
    {
      label: "Contact Us",
      to: "/contact-us"
    }
  ];

  let forFeatures = [
    {
      label: "Amenity Bookings",
      to: "/amenity-bookings"
    },
    {
      label: "Announcements",
      to: "/announcements"
    },
    {
      label: "Bulletin Board",
      to: "/bulletin-board"
    },
    {
      label: "Events Calender",
      to: "/events-calendar"
    },
    {
      label: "Maintenance Requests",
      to: "/maintenance-requests"
    }
  ];

  let forBlog = [
    {
      label: "Landlord and Tenant Board",
      to: "https://tribunalsontario.ca/ltb/"
    },
    {
      label: "The Association of Condominium Managers of Ontario",
      to: "https://www.acmo.org/"
    },
    {
      label: "Condominium Authority of Ontario",
      to: "https://www.condoauthorityontario.ca/"
    }
  ];

  const {t} = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };
  return (
    <>
      <Section bg="dark" className="pt-8 pb-4 light">
        <div
          className="position-absolute btn-back-to-top cursor-pointer bg-dark"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />
        </div>
        <Row>
          <Col lg={4}>
            <FooterTitle>{t("footer.our_mission")}</FooterTitle>
            <p className="text-600">{t("footer.para_1")}</p>
            <IconGroup className="mt-4" icons={bgWhiteIcons} />
          </Col>
          <Col className="ps-lg-6 ps-xl-8">
            <Row className="mt-5 mt-lg-0">
              <Col xs={6} md={3}>
                <FooterTitle>{t("footer.company")}</FooterTitle>
                <ul className="list-unstyled">
                  {forCompany?.map((v, i) => (
                    <li key={i + "23456"} className="mt-3 cursor-pointer">
                      <a
                        href={v.to}
                        className="text-secondary"
                        onClick={() => moveToPath(v.to)}
                      >
                        {v.label}
                      </a>
                    </li>
                  ))}
                  {/* <li
                    className="mb-1 cursor-pointer"
                    onClick={() => moveToPath("/about-condozak")}
                  >
                    {t("footer.about")}
                  </li>
                  <li
                    className="mb-1 cursor-pointer"
                    onClick={() => moveToPath("/contact-us")}
                  >
                    {t("footer.contact")}
                  </li>
                  <li className="mb-1">{t("footer.careers")}</li>
                  <li className="mb-1">{t("footer.blog")}</li>
                  <li className="mb-1">
                    <Link to="/terms-and-privacy">{t("footer.terms")}</Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/terms-and-privacy">{t("footer.privacy")}</Link>
                  </li>
                  <li className="mb-1">{t("footer.imprint")}</li> */}
                </ul>
              </Col>
              <Col xs={6} md={3}>
                <FooterTitle>{t("footer.product")}</FooterTitle>
                <ul className="list-unstyled">
                  {forFeatures?.map((v, i) => (
                    <li
                      key={i + "234931"}
                      className="mt-2 cursor-pointer"
                    >
                      <a
                        href={v.to}
                        className="text-secondary"
                        onClick={() => moveToPath(v.to)}
                      >
                        {v.label}
                      </a>
                    </li>
                  ))}
                  {/* <li className="mb-1">{t("footer.features")}</li>
                  <li className="mb-1">{t("footer.roadmap")}</li>
                  <li className="mb-1">{t("footer.changelog")}</li>
                  <li
                    className="mb-1 cursor-pointer"
                    onClick={() => moveToPath("/pricing")}
                  >
                    {t("footer.pricing")}
                  </li>
                  <li className="mb-1">{t("footer.docs")}</li>
                  <li className="mb-1">{t("footer.system_status")}</li>
                  <li className="mb-1">{t("footer.agencies")}</li>
                  <li className="mb-1">{t("footer.enterprise")}</li> */}
                </ul>
              </Col>
              <Col xs={6} md={3} className="mt-5 mt-md-0">
                <FooterTitle>USEFUL LINKS</FooterTitle>
                <ul className="list-unstyled">
                  {forBlog?.map((v, i) => (
                    <li
                      key={i + "847"}
                      className="cursor-pointer mt-3"
                      style={{width: "300px"}}
                    >
                      {v.label}
                      <br />
                      <a
                        className="text-white opacity-85"
                        href={v.to}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {v.to}
                      </a>
                    </li>
                  ))}
                  {/* <li>
                    <h5 className="fs-0 mb-0">
                      <Link className="text-600" to="#!">
                        {t("footer.line_1")}
                      </Link>
                    </h5>
                    <p className="text-600 opacity-50">
                      {t("footer.line_1_date")}{" "}
                    </p>
                  </li>
                  <li>
                    <h5 className="fs-0 mb-0">
                      <Link className="text-600" to="#!">
                        {t("footer.line_2")}
                      </Link>
                    </h5>
                    <p className="text-600 opacity-50">
                      {t("footer.line_2_date")}{" "}
                    </p>
                  </li>
                  <li>
                    <h5 className="fs-0 mb-0">
                      <Link className="text-600" to="#!">
                        {t("footer.line_3")}
                      </Link>
                    </h5>
                    <p className="text-600 opacity-50">
                      {t("footer.line_3_date")}{" "}
                    </p>
                  </li>
                  <li>
                    <h5 className="fs-0 mb-0">
                      <Link className="text-600" to="#!">
                        {t("footer.line_4")}
                      </Link>
                    </h5>
                    <p className="text-600 opacity-50">
                      {t("footer.line_4_date")}{" "}
                    </p>
                  </li> */}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>

      <section className=" bg-dark py-0 text-center fs--1 light">
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between align-content-center">
            <Col xs={12} md={12} sm="auto" className="text-center">
              <p className=" mb-0 text-600">
                {/* {t("footer.thanks_for_choosing_condozak")}{" "} */}
                CONDOZAK™ is a product of
                <a
                  className="text-white opacity-85 ms-1"
                  href="https://www.truzak.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Truzak Technology Inc.
                </a>
              </p>
            </Col>
            {/* <Col xs={12} sm="auto">
              <FontAwesomeIcon
                icon={["fab", "facebook"]}
                className="text-light fs-1 me-3"
                style={{cursor: "pointer"}}
              />
              <FontAwesomeIcon
                icon={["fab", "twitter"]}
                className="text-light fs-1 me-3"
                style={{cursor: "pointer"}}
              />
              <FontAwesomeIcon
                icon={["fab", "youtube"]}
                className="text-light fs-1 me-3"
                style={{cursor: "pointer"}}
              />
            </Col> */}
          </Row>
        </div>
      </section>
    </>
  );
};

export default FooterStandard;
