// import Communications from "assets/img/icons/spot-illustrations/latest/Communications.png";
// import Recordkeeping from "assets/img/icons/spot-illustrations/latest/Record-Keeping.png";
// import ResidentPortal from "assets/img/icons/spot-illustrations/latest/Resident-Portal.png";
import idea from "../../assets/img/Features/07 EVENTS--Stay-Up-to-Date.png";
import team from "../../assets/img/Features/08 CALENDAR-Plan-Ahead.png";
// import work from 'assets/img/illustrations/work.png';
export default [
  {
    icon: ["far", "lightbulb"],
    iconText: "EVENTS",
    color: "danger",
    title: "Stay Up-to-Date",
    description:
      "Never miss out on an event again with CONDOZAK's Events Calendar, the easiest way to stay informed about upcoming activities in your community.",
    image: idea
  },
  {
    icon: ["far", "object-ungroup"],
    iconText: "CALENDAR",
    color: "info",
    title: "Plan Ahead",
    description:
      "With CONDOZAK's Events Calendar, property managers can easily publish all the events and their details on the calendar of a particular property/building, giving residents a great visual experience to browse for upcoming events and plan their schedules accordingly.",
    image: team,
    inverse: true
  }
  // {
  //   icon: ["far", "paper-plane"],
  //   iconText: "SYSTEMS",
  //   color: "success",
  //   title: "Technology Stack",
  //   description:
  //     "CONDOZAK is built using the MERN (MongoDB, Express.js, React.js, Node.js) stack, providing a fast, modern, and scalable solution for property management. With AWS (Amazon Web Services) cloud hosting, CONDOZAK delivers robust and reliable performance while ensuring maximum security and data privacy.",
  //   image: work
  // }
];
